
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
import userServer, { User } from '@/api/user'
import security from '@/api/security'
import { reject } from 'lodash'

@Component({ name: 'StepOne', components: {} })
export default class extends Vue {
  private user: User = {};

  // 是否首次点击验证码
  firstCheckCodeFlag = true
  // 取得下一个验证码的时间间隔
  getCheckCodeInterval=0

  form = {
    phone: '',
    userLoginName: '',
    pwd: '',
    rePwd: '',
    checkCode: ''
  };

  // 自定义表单验证
  validatePwd = (rule: any, value: string, callback: any) => {
    const patrn = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@_$!%*?&]{6,16}/

    if (!value || value === '') {
      callback(new Error('请输入确认密码'))
    } else if (value.length < 6 || value.length > 16) {
      callback(new Error('密码长度不能少于6位且不能多于16位'))
    } else if (!patrn.test(value)) {
      callback(new Error('密码格式错误, 密码中需要含有大小写字母和数字, 可加入$@_$!%*?&符号'))
    } else {
      callback()
    }
  }

  // 自定义表单验证
  validatePass = (rule: any, value: string, callback: any) => {
    if (!value || value === '') {
      callback(new Error('请输入确认密码'))
    } else if (value !== this.form.pwd) {
      callback(new Error('密码不一致'))
    } else {
      callback()
    }
  }

  // 自定义表单验证
  validatePhone = (rule: any, value: string, callback: any) => {
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (!value || value === '') {
      callback(new Error('请输入手机号'))
      return
    } else if (!reg.test(value)) {
      callback(new Error('请输入正确的手机号'))
      return
    }
    security.ensurePhoneRegister(value)
      .then((response) => {
        if (!response.data) {
          callback()
        } else {
          callback(new Error(`手机号${value}已经被注册过了`))
        }
      })
  }

  // 自定义表单验证
  validateUserLoginName = (rule: any, value: string, callback: any) => {
    if (value === '') {
      callback(new Error('请输入登录账号'))
      return
    }
    if (value.length > 8) {
      callback(new Error('登录账号长度最大为8个字符'))
      return
    }
    // 从服务器确认用户是否正确
    security.ensureUserRegister(value)
      .then((response) => {
        if (!response.data) {
          callback()
        } else {
          callback(new Error(`用户名（${value}）已经被注册，换一个吧`))
        }
      }).catch(err => {
        callback(new Error('验证用户名是否可用失败:' + err.message))
      })
  }

  // 表单验证规矩
  ruleUser = {
    phone: [{ validator: this.validatePhone, trigger: 'blur' }],
    pwd: [{ validator: this.validatePwd, trigger: 'blur' }],
    rePwd: [{ validator: this.validatePass, trigger: 'blur' }],
    userLoginName: [{ validator: this.validateUserLoginName, trigger: 'blur' }],
    checkCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }]
  };

  // 表单验证
  checkForm () {
    const checkform = this.$refs.formRef as Form
    checkform.validate((valid) => {
      if (valid) {
        if (this.form.pwd !== this.form.rePwd) {
          this.$Message.error({ content: '密码与确认密码不一致！', duration: 3 })
          return
        }
        this.toNext()
      } else {
        this.$Message.error({ content: '数据错误，请按要求输入内容！', duration: 3 })
      }
    })
  }

  // 执行注册操作并跳转到下一步
  toNext () {
    this.user.phone = this.form.phone
    this.user.userCode = ''
    this.user.nickName = this.form.phone
    this.user.userLoginName = this.form.userLoginName
    this.user.pwd = this.form.pwd
    security
      .userRegister(this.user, this.form.checkCode)
      .then((resp) => {
        this.$emit('toNext', 2, resp.data)
      })
      .catch((err) => {
        this.$Message.error({ content: '注册失败：' + err.message, duration: 3 })
      })
  }

  // 发送短信验证
  sendSmsCheckCode () {
    (new Promise<string>((resolve, reject) => {
      this.validatePhone(null, this.form.phone, (err?: Error) => {
        if (err) {
          reject(err)
        } else {
          resolve(this.form.phone)
        }
      })
    }))
      .then((phone) => {
        return security.sendPhoneCheckCode(this.form.phone)
      })
      .then(() => {
        this.firstCheckCodeFlag = false
        this.$Message.info('短信已经发下到您手机，请注意查收')
        this.getCheckCodeInterval = 30
        const that = this
        const hander = setInterval(() => {
          that.getCheckCodeInterval--
          if (that.getCheckCodeInterval === 0) {
            clearInterval(hander)
          }
        }, 1000)
      }).catch((err) => {
        this.$Message.error('短信发送错误:' + err.message)
      })
  }
}
