var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"expand"}),_c('div',{staticClass:"showContent"},[_vm._m(0),_c('div',{staticClass:"tip"},[_c('span',[_vm._v(_vm._s(_vm.stepTitle))])]),_c('div',{staticClass:"step"},[_c('div',{staticStyle:{"margin-left":"126px"}},[_c('img',{attrs:{"draggable":"false","src":_vm.stepIndex === 1
              ? require('@/assets/register/blue-point.png')
              : require('@/assets/register/grey-point.png')}}),_c('span',{class:_vm.stepIndex === 1 ? 'stepShowCss' : 'stepHideCss'},[_vm._v("01 注册账号")])]),_c('div',{staticStyle:{"margin-left":"191px","margin-right":"191px"}},[_c('img',{attrs:{"draggable":"false","src":_vm.stepIndex === 2
              ? require('@/assets/register/blue-point.png')
              : require('@/assets/register/grey-point.png')}}),_c('span',{class:_vm.stepIndex === 2 ? 'stepShowCss' : 'stepHideCss'},[_vm._v("02 选择团队")])]),_c('div',[_c('img',{attrs:{"draggable":"false","src":_vm.stepIndex >= 3
              ? require('@/assets/register/blue-point.png')
              : require('@/assets/register/grey-point.png')}}),_c('span',{class:_vm.stepIndex >= 3 ? 'stepShowCss' : 'stepHideCss'},[_vm._v("03 团队申请")])])])]),_c('div',{staticClass:"stepContent"},[_c('StepOne',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepIndex === 1),expression:"stepIndex === 1"}],on:{"toNext":_vm.nextStep}}),_c('StepTwo',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepIndex === 2),expression:"stepIndex === 2"}],attrs:{"userName":_vm.user.nickName,"userCode":_vm.user.userCode},on:{"toNext":_vm.nextStep}}),_c('StepThree',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepIndex === 3),expression:"stepIndex === 3"}],attrs:{"userId":_vm.user.id},on:{"toNext":_vm.nextStep,"back":_vm.backStep}}),_c('StepThreeCreateTeam',{directives:[{name:"show",rawName:"v-show",value:(_vm.stepIndex === 4),expression:"stepIndex === 4"}],attrs:{"userId":_vm.user.id},on:{"toNext":_vm.nextStep,"back":_vm.backStep}})],1),_c('div',{staticClass:"expand"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"signboard"},[_c('img',{attrs:{"src":require("@/assets/register/xbg-logo.png"),"draggable":"false"}}),_c('div',{staticStyle:{"margin-left":"20px"}}),_c('img',{attrs:{"src":require("@/assets/register/xbg-coding-new.png")}})])
}]

export { render, staticRenderFns }