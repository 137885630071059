
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
@Component({ name: 'StepTwo', components: {} })
export default class extends Vue {
    selectLable = 'toJoin'

    @Prop({
      type: String,
      default: ''
    })
    userName !: string;

    @Prop({
      type: String,
      default: ''
    })
    userCode !: string;

    // 到下一步
    toNext () {
      if (this.selectLable === 'toCreate') {
        this.$emit('toNext', 4)
      } else {
        this.$emit('toNext', 3)
      }
    }
}
