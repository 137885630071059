
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
import teamServer, { Team } from '@/api/team'
import axios from 'axios'
import security from '@/api/security'

@Component({ name: 'StepThreeCreateTeam', components: {} })
export default class extends Vue {
  backTipText = '< 返回上一步';
  joinSuccess = false;

  team: Team = {};

  @Prop({
    type: Number,
    default: undefined
  })
  userId!: number;

  validateTeamName = (rule: any, value: any, callback: any) => {
    if (!this.team.name || this.team.name === '') {
      callback(new Error('团队名称不能为空'))
      return
    }
    teamServer.checkNewTeamName(this.team.name).then((res) => {
      if (res.code === 0) {
        if (res.data) {
          callback()
        } else {
          callback(new Error('团队名称不能重复'))
        }
      }
    })
  };

  // 新增规则
  teamNameRule = [{ required: true, validator: this.validateTeamName, trigger: 'blur' }];

  // 执行创建团队操作
  toCreate () {
    const form = this.$refs.form as Form
    // 获取到当前注册IP
    form.validate((valid) => {
      if (!valid) {
        this.$Message.error('数据校验失败,请检查!')
        return
      }
      security
        .createNewTeam(this.team.name!, this.userId)
        .then((resp) => {
          this.team = resp.data!
          this.joinSuccess = true
        })
        .catch((err) => {
          this.$Message.error({ content: '创建团队失败：' + err.message, duration: 3 })
        })
    })
  }

  // 返回上一步
  toBack () {
    this.$emit('back', 2)
  }

  // 去往登录页
  toLogin () {
    this.$router.push('/login')
  }
}
