
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
import tServer, { TeamUse } from '@/api/team-user'
import teamServer, { Team } from '@/api/team'
import security from '@/api/security'

@Component({ name: 'StepThree', components: {} })
export default class extends Vue {
  teamUser: TeamUse = {};
  teamId = -1;
  teamCode = '';
  teamName = '';

  @Prop({
    type: Number,
    default: undefined
  })
  userId!: number;

  backTipText = '< 返回上一步';
  joinSuccess = false;

  // 输入团队编号后获取团队信息
  getTeamInfo () {
    if (this.teamCode) {
      security
        .getItemByTeamCode(this.teamCode)
        .then((resp) => {
          const item = resp.data!
          if (item) {
            this.teamId = item.id!
            this.teamName = item.name!
          } else {
            this.teamId = -1
            this.teamName = ''
          }
        })
        .catch((err) => {
          this.$Notice.error({
            title: '错误',
            desc: '获取数据异常：' + err.message
          })
        })
    }
  }

  @Watch('teamCode')
  watchTeamCode (newCode: string) {
    if (newCode) {
      this.getTeamInfo()
    }
  }

  // 执行注册操作并跳转到下一步
  toJoin () {
    if (!this.teamCode) {
      this.$Notice.warning({
        title: '错误',
        desc: '请输入团队编号！',
        duration: 3
      })
      return
    }
    this.teamUser.userId = this.userId
    this.teamUser.teamId = this.teamId
    security
      .createTeamUser({
        teamCode: this.teamCode,
        userId: this.userId
      })
      .then((resp) => {
        this.joinSuccess = true
        this.teamUser = resp.data!
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '加入团队失败：' + err.message,
          duration: 3
        })
      })
  }

  // 返回上一步
  toBack () {
    this.$emit('back', 2)
  }

  // 去往主页
  toLogin () {
    this.$router.push('/login')
  }
}
