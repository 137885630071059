
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
import StepOne from './step-one.vue'
import StepTwo from './step-two.vue'
import StepThree from './step-three.vue'
import StepThreeCreateTeam from './step-three-create-team.vue'
import { Machine } from 'xstate'
import { User } from '@/api/user'

@Component({
  name: 'registered',
  components: { StepOne, StepTwo, StepThree, StepThreeCreateTeam }
})
export default class extends Vue {
  stepIndex = 1;

  user: User = {};

  get stepTitle () {
    if (this.stepIndex === 1) return '注册账号'
    if (this.stepIndex === 2) return '选择团队'
    if (this.stepIndex > 2) return '团队申请'
  }

  // 切换下一步骤点
  nextStep (index: number, newUser?: User) {
    if (newUser) {
      this.user = newUser
    }
    this.stepIndex = index
  }

  // 返回上一步骤 index为负数
  backStep (index: number) {
    this.stepIndex = index
  }
}
